import './webpack-imports';
import './sentry';
import './custom-elements';
import { applyPolyfills } from './polyfills';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import barba from '@barba/core';

import { initBaseView } from './views/_base';
import lazyView from './views/_lazy';

import { preloader } from './components/preloader';
import { timeout } from './utils/timeout';

import BaseTransition from './transitions/base';

import { initButtonHover } from './inits/button-hover';
import select from './inits/select';
import headerThemeObserver from './inits/header-theme-observer';
import splitLines from './inits/split-lines';
import splitChars from './inits/split-chars';
import reveal from './inits/reveal';

import ajaxForm from './inits/ajax-forms';
import inputFocus from './inits/inputs-focus';
import inputMasks from './inits/masks';

import handleFeedbackPopupOpenInit from './inits/feedback-popup';
import menuPopup from './inits/menu-popup';
import anchors from './inits/anchors';
import hideHeaderOnScrollInit from './inits/header-scroll-observe';
import { calculateScrollbarWidth } from '../modules/calculate-scrollbar-width';
import vHfix from '../modules/vh-mobile-fix';
import { CustomEase } from 'gsap/CustomEase';

document.documentElement.classList.add('js-ready');
applyPolyfills().then(() => {
    gsap.config({ nullTargetWarn: false });
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, InertiaPlugin, Draggable, CustomEase);
    // Preloader init

    initBaseView();
    initButtonHover();
    hideHeaderOnScrollInit();
    calculateScrollbarWidth();
    vHfix.init();
    menuPopup.init();

    const menuPopupElement = document.querySelector<HTMLElement>('[data-lit-popup="menu"]');
    if (menuPopupElement) {
        anchors.init(menuPopupElement);
    }

    const headerElement = document.querySelector<HTMLElement>('.js-header');
    if (headerElement) {
        anchors.init(headerElement);
    }

    const stopPropagation = (e: Event) => e.stopImmediatePropagation();

    document.querySelectorAll('.lit-popup-container').forEach((el) => {
        if (el.scrollHeight > el.clientHeight) {
            el.addEventListener('wheel', stopPropagation);
            el.addEventListener('touchstart', stopPropagation);
        }
    });

    const header = document.querySelector<HTMLElement>('.js-header');

    if (header) {
        select.init(header);
    }

    const footer = document.querySelector<HTMLElement>('.js-footer');

    if (footer) {
        headerThemeObserver.init(footer);
        splitLines(footer);
        splitChars(footer);
        reveal.init(footer);
    }

    ajaxForm.init();
    inputFocus.init();
    inputMasks.init();

    handleFeedbackPopupOpenInit();

    preloader
        .loadAssets()
        .then(() => timeout(100))
        .then(() => {
            preloader.leave();
        })
        .then(() => {
            barba.init({
                transitions: [BaseTransition],
                views: [
                    lazyView('index-page', () =>
                        import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('list-page', () =>
                        import(/* webpackChunkName: "list-page", webpackPrefetch: true */ './views/list').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('about-page', () =>
                        import(/* webpackChunkName: "about-page", webpackPrefetch: true */ './views/about').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('content-page', () =>
                        import(/* webpackChunkName: "content-page", webpackPrefetch: true */ './views/content').then(
                            (m) => m.default,
                        ),
                    ),
                    lazyView('error-page', () =>
                        import(/* webpackChunkName: "error-page", webpackPrefetch: true */ './views/error').then(
                            (m) => m.default,
                        ),
                    ),
                ],
                prefetchIgnore: true,
                preventRunning: true,
                // prevent: ({ el }) => el.classList.contains('chps-content-list-pagination-link'),
                timeout: 5000,
                debug: NODE_ENV === 'development',
                requestError: (trigger, action, url, response) => {
                    // go to a custom 404 page if the user click on a link that return a 404 response status
                    if (action === 'click' && response.status && response.status === 404) {
                        barba.go('/404');
                    }

                    // prevent Barba from redirecting the user to the requested URL
                    // this is equivalent to e.preventDefault() in this context
                    return false;
                },
            });
        });
});

// Always at the end
// module.hot?.accept();
