import debounce from 'lodash.debounce';

const vh = window.innerHeight * 0.01;

const calculateVhOnResize = debounce(() => {
    const newVh = window.innerHeight * 0.01;

    if (newVh > vh) {
        document.documentElement.style.setProperty('--vh', `${newVh}px`);
    }
}, 40);

const calculateVhOnOrientationChange = debounce(() => {
    const newVh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${newVh}px`);
}, 50);

const init = () => {
    if (navigator.userAgent.toLowerCase().indexOf('chrome') !== -1) {
        // В Google Chrome все норм, нет нужды в этом скрипте
        return;
    }

    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', calculateVhOnResize);
    window.addEventListener('orientationchange', calculateVhOnOrientationChange);

    return () => {
        window.removeEventListener('resize', calculateVhOnResize);
        window.removeEventListener('orientationchange', calculateVhOnOrientationChange);
    };
};

const _module = { init, calculateVhOnResize };

export default _module;
